.skills {
    margin: 5rem auto;
    padding: 1rem;
    width: 100%;
}

.skills .container {
    width: 1240px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.skills .col-1 {
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.skills .col-1 p:nth-child(1) {
    font-size: 1.8rem;
    font-style: italic;
}

.skills .col-1 p:nth-child(2) {
    font-size: 3rem;
    font-weight: 600;
    margin: .5rem 0;
}

.skills .col-1 p:nth-child(3) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}


.skills .col-2 {
    margin: auto;
}


@media screen and (max-width: 940px) {
    .skills .container {
        max-width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }

    .skills iframe {
        width: 100%;
        height: auto;
    }
}











